// Variables
$black-900: #151515;
$darkpurple-700: #3b26d1;
$darkpurple-800: #241e82;
$gold: #8d6d19;
$lightpurple: #7d87cc;

$font-family-arial: Arial;
$font-family-micorsoft-sans-serif: "Microsoft Sans Serif";
$font-family-mongolian: "Mongolian Baiti";
$font-family-segoe: "Segoe UI";
$font-family-times-new-roman: "Times New Roman";

// Custom bootstrap variables
$body-color: $black-900;
$btn-white-space: nowrap;
$dark: black;
$info: #145db4;
$light: #f7f7f7;
$primary: #013067;
$secondary: #707070;
$success: $gold;

@import "bootstrap/scss/bootstrap.scss";

:export {
    black-900: $black-900;
    darkpurple-700: $darkpurple-700;
    darkpurple-800: $darkpurple-800;
    gold: $gold;
    lightpurple: $lightpurple;

    white: $white;
    gray: $gray-600;
    gray-100: $gray-100;
    gray-100: $gray-100;
    gray-200: $gray-200;
    gray-300: $gray-300;
    gray-400: $gray-400;
    gray-500: $gray-500;
    gray-600: $gray-600;
    gray-700: $gray-700;
    gray-800: $gray-800;
    gray-900: $gray-900;
    black: $black;

    blue: $blue;
    indigo: $indigo;
    purple: $purple;
    pink: $pink;
    red: $red;
    orange: $orange;
    yellow: $yellow;
    green: $green;
    teal: $teal;
    cyan: $cyan;

    primary: $primary;
    secondary: $secondary;
    success: $success;
    info: $info;
    warning: $warning;
    danger: $danger;
    light: $light;
    dark: $dark;

    spacer: $spacer;

    font-family-sans-serif: $font-family-sans-serif;
    font-family-monospace: $font-family-monospace;
    font-family-base: $font-family-base;

    font-family-arial: $font-family-arial;
    font-family-micorsoft-sans-serif: $font-family-micorsoft-sans-serif;
    font-family-mongolian: $font-family-mongolian;
    font-family-segoe: $font-family-segoe;
    font-family-times-new-roman: $font-family-times-new-roman;

    font-family-button: $font-family-micorsoft-sans-serif, $font-family-base;
    font-family-link: $font-family-micorsoft-sans-serif, $font-family-base;
    font-family-text: $font-family-segoe, $font-family-arial, $font-family-base;
    font-family-title: $font-family-mongolian, $font-family-times-new-roman, $font-family-base;

    font-size-base: $font-size-base;
    font-size-lg: $font-size-lg;
    font-size-sm: $font-size-sm;

    font-weight-lighter: $font-weight-lighter;
    font-weight-light: $font-weight-light;
    font-weight-normal: $font-weight-normal;
    font-weight-bold: $font-weight-bold;
    font-weight-bolder: $font-weight-bolder;

    font-weight-base: $font-weight-base;
    line-height-base: $line-height-base;

    h1-font-size: $h1-font-size;
    h2-font-size: $h2-font-size;
    h3-font-size: $h3-font-size;
    h4-font-size: $h4-font-size;
    h5-font-size: $h5-font-size;
    h6-font-size: $h6-font-size;

    display1-size: $display1-size;
    display2-size: $display2-size;
    display3-size: $display3-size;
    display4-size: $display4-size;
    display5-size: 3rem;
    display6-size: 2.5rem;
    display7-size: 2rem;
    display8-size: 1.5rem;

    table-border-width: $table-border-width;
    table-border-color: $table-border-color;
}
