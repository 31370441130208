@mixin breakpoint($media) {
  @if $media == smartphone {
    @media only screen and (min-width: 320px) and (max-width: 425px) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: 426px) and (max-width: 1024px) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (min-width: 320px) and (max-width: 1024px) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Lato;

  h2 {
    font-weight: bold;
  }
}

.main {
  width: 100%;
  height: 1000pxs;

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #000066;
  }
  .nav.nav-tabs {
    a.nav-link {
      color: rgba(20, 93, 180, 0.5);
      &.active {
        color: #145db4;
      }
    }
  }
  .nav-item {
    a {
      color: #000066;
    }
  }
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
  .popup-wrapper {
    display: none;
    &.on {
      display: block;
    }
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    .curtain {
      height: 100%;
      width: 100%;
      background-color: gray;
      opacity: 0.6;
    }
    .box {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      @include breakpoint(smartphone) {
        width: 90%;
      }
      button.btn {
        display: block;
        margin: 10px auto;
      }
    }
  }
  header {
    .navbar.navbar-light {
      background-color: #000000;
      .navbar-brand {
        //margin-left: 20px;
      }
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2820, 93, 180, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
      .navbar-nav .nav-link {
        color: white;
      }

      #basic-navbar-nav {
        @include breakpoint(desktop) {
          display: block !important;
        }
      }
      .mr-auto.navbar-nav {
        float: right;
        a.nav-link {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          //text-transform: uppercase;
          letter-spacing: 1px;
          &:hover {
            border-bottom: 3px solid #145db4;
          }
        }
        .dropdown.nav-item {
          .dropdown-toggle::after {
            display: none;
          }
          .dropdown-menu {
            background-color: unset;
            margin-top: 40px !important;
            border: unset;
            a {
              color: white;
              font-weight: bold;
              &:hover {
                background-color: unset;
                color: #145db4;
              }
            }
            .dropdown-item {
              padding: 4px 8px;
            }
          }
        }

        .lang-divideline {
          display: none;
          @include breakpoint(desktop) {
            display: block;
            border-left: 2px solid #fff;
            height: 20px;
            margin-top: 8px;
          }
        }
      }
      ul {
        padding-left: 20px;
        margin: auto;
        li {
          color: white;
          display: inline-block;
          margin-right: 5px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          &:hover {
            cursor: pointer;
          }
          &:nth-child(1),
          &:nth-child(2) {
            border-right: 2px solid white;
            padding-right: 5px;
          }
        }
        @include breakpoint(smartphone) {
          position: absolute;
          top: 30px;
          right: 70px;
        }
        @include breakpoint(tablet) {
          position: absolute;
          top: 30px;
          right: 15%;
        }
      }
      .lang {
        .dropdown-menu {
          left: unset;
          right: 0;
          min-width: unset;
        }
      }
    }
    .curtain {
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 280px;
      position: fixed;
      top: 86px;
      z-index: 1000;
      .wrapper {
        color: white;
        width: 28%;
        margin: 20px 0 20px 0;
        .title {
          display: none;
          @include breakpoint(desktop) {
            display: block;
            font-size: 24px;
            font-weight: bold;
          }
        }
        .brief {
          display: none;
          @include breakpoint(desktop) {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }

  footer {
    .container {
      padding: 40px 0;
      @include breakpoint(smartphone) {
        width: 90%;
      }
    }
    .footer1 {
      background-color: #d4e1f0;
      color: black;

      h3 {
        font-weight: bold;
      }
      .city {
        font-size: 16px;
        font-weight: bold;
        margin: 5px 0;
      }
      .field {
        font-weight: bold;
      }
      .footer-menu {
        font-weight: bold;
        padding-top: 40px;
        .item {
          margin-bottom: 15px;
        }
        a {
          color: #000000;
        }
      }
      .btns {
        @include breakpoint(mobile) {
          margin-top: 20px;
        }
        .btn {
          width: 220px;
          color: white;
          font-weight: bold;
          padding: 15px 40px;
          background-color: #002653;
          border-radius: 30px;
          &.new-account {
            @include breakpoint(desktop) {
              margin-top: 90px;
            }
          }
          &.client-login {
            @include breakpoint(desktop) {
              margin-top: 30px;
            }
            @include breakpoint(tablet) {
              //margin-left: 20px;
              float: right;
            }
            @include breakpoint(smartphone) {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .footer2 {
      background-color: #000000;
      font-size: 14px;
      color: #707070;
      line-height: 2;

      .disclaimer {
        font-size: 12px;
        text-align: left;
        line-height: 1.42;
        display: table;
        margin: 0 auto;
        padding-bottom: 40px;
        border-bottom: 1px solid #999;
      }
      .copyright {
        //font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 1.42;
        padding-top: 20px;
        padding-bottom: 80px;

        &.au {
          ul {
            padding-left: 0;
            @include breakpoint(desktop) {
              float: left;
            }
            li {
              padding-left: 0;
              border-left: none;
            }
          }
          span {
            @include breakpoint(desktop) {
              float: right;
            }
          }
        }
        &.non-au {
          margin: 0 auto;
        }
        ul {
          padding-left: 10px;

          text-align: center;
          list-style: none;
          li {
            display: inline-block;
            border-left: 1px solid #a8a8a8;
            &:nth-child(1) {
              border-left: none;
            }
            padding: 0 20px;
            a {
              color: #707070;
            }
          }
        }
      }
    }
  }

  .promoteSec {
    color: #fff;
    margin: 100px 0;
    background-color: #241e82;
    .textBlock {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding-bottom: 100px;
      min-width: 260px;
      @include breakpoint(smartphone) {
        top: 600px;
        z-index: 100;
        padding-bottom: 0;
      }
      p {
        font-weight: 700;
        line-height: 40px;
        font-size: 20px;
        margin: 0;
        @include breakpoint(desktop) {
          font-size: 24px;
        }
      }
      .btn {
        margin-top: 24px;
        font-size: 16px;
        width: 100%;
      }
    }
    .img {
      width: 100%;
      margin-top: 60px;
      max-width: 517px;
      &:before {
        content: "";
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("./banner/mobile_device-3.webp"), url("./banner/mobile_device-3.png");
        width: 100%;
        padding-bottom: 97%;
        background-size: 100% auto;
        background-position: center 0;
        @include breakpoint(smartphone) {
          padding-bottom: 170%;
        }
      }
    }
  }

  .content {
    margin-top: 86px;
    .container {
      padding: 40px 0;
      @include breakpoint(smartphone) {
        width: 90%;
      }
    }
    section.banner-template {
      .banner {
        &.about_us {
          background-image: url("./banner/banner_about.jpg");
        }
        &.product_service {
          background-image: url("./banner/new_banner_product.jpg");
        }
        &.account_open {
          background-image: url("./banner/new_banner_acc_open.jpg");
        }
        &.platform {
          background-image: url("./banner/new_banner_platform.jpg");
        }
        &.deposit_withdraw {
          background-image: url("./banner/new_banner_deposit.jpg");
        }
        &.financial_reports {
          background-image: url("./banner/banner_investors.jpg");
        }
        &.location {
          background-image: url("./banner/new_banner_home.jpg");
        }
        &.legal {
          background-image: url("./banner/new_banner_legal.jpg");
        }

        //background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: left top;
        @include breakpoint(desktop) {
          background-size: unset;
        }
        width: 100%;
      }
      .floatBox {
        width: 70%;
        //height: 320px;
        padding: 250px 0;
        color: white;
        margin: auto;
        @include breakpoint(mobile) {
          width: 90%;
          padding: 100px 0;
        }

        .title {
          text-align: center;
          h2 {
            font-size: 60px;
            font-weight: bold;
            @include breakpoint(mobile) {
              font-size: 48px;
            }
          }
        }
        .brief {
          text-align: center;
        }
        @include breakpoint(mobile) {
          .brief {
            margin: 20px auto;
          }
        }
      }
    }
    section.no-match {
      max-width: 1360px;
      text-align: center;
      margin: 0 auto;
      padding: 8em 0;
    }

    section.why-mt5 {
      background-color: #f8f9fa;
      .row {
        margin-top: 30px;
        .title {
          font-size: 22px;
          font-weight: bold;
          color: #145db4;
        }
        ul {
          padding-left: 20px;
          margin-top: 20px;
        }
      }
    }

    section.platform-belt {
      background-image: url("./banner/platform-belt.jpg");
      text-align: center;
      color: white;
      .container {
        padding: 100px 0;
        h4 {
          margin-top: 30px;
          font-weight: 600;
          font-style: italic;
        }
        .btn {
          color: #002652;
          font-weight: bold;
          width: 220px;
          margin-top: 30px;
          font-weight: bold;
          padding: 15px 40px;
          background-color: white;
          border-radius: 30px;
        }
      }
    }

    section.campaign {
      background-image: url("./banner/home_campaign.webp"), url("./banner/home_campaign.jpg");
      background-size: cover;

      .wrapper {
        margin: auto;
        width: 60%;
        color: white;
        text-align: center;
        padding: 80px 0;
        @include breakpoint(mobile) {
          padding: 40px 0;
        }

        .latest {
          background-color: #145db4;
          width: 220px;
          margin: auto;
          font-size: 18px;
          @include breakpoint(mobile) {
            font-size: 16px;
          }
          font-weight: bold;
        }
        .analysis {
          margin-top: 20px;
          font-size: 50px;
          @include breakpoint(mobile) {
            font-size: 36px;
          }
          font-weight: bold;
        }
        .analysis-text {
          font-size: 24px;
          @include breakpoint(mobile) {
            font-size: 20px;
          }
          font-weight: 400;
        }
        .opportunity {
          margin-top: 30px;
          a {
            color: white;
            font-size: 18px;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }

    .container {
      //margin-top: 100px;
      //margin-bottom: 50px;
      &.disclaimer {
        .text {
          margin-top: 40px;
        }
      }
      &.term-and-policy {
        h1 {
          text-align: center;
          margin: 4em 0 2em 0;
        }
      }
      &.intermediate {
        text-align: center;
        h2 {
          padding-top: 150px;
        }
        a {
          display: block;
          margin: 90px 0 80px;
        }
      }
      &.about-us {
        .board-info {
          .block {
            margin-bottom: 30px;
            .title {
              font-size: 24px;
              color: #006;
              font-weight: 600;
            }
            .name {
              margin-top: 10px;
              font-weight: 600;
            }
            .intro {
              white-space: pre-line;
              margin-bottom: 10px;
            }
          }
        }
        .milestone {
          .block {
            margin-bottom: 10px;
            .year {
              color: #fff100;
              font-size: 20px;
              font-weight: 500px;
            }
          }
        }
      }

      &.product {
        .brief {
          margin-top: 20px;
        }
        .commodities {
          .title {
            @include breakpoint(desktop) {
              width: 50%;
            }
          }
        }
        .block {
          margin-top: 40px;
          &.fluc {
            background-color: #f8f9fa;
            &:before {
              content: "";
              background-color: #f8f9fa;
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              z-index: -1;
            }
          }
          &.silver {
            padding-top: 40px;
          }
          .title {
            padding-top: 20px;
            h2 {
              font-weight: bold;
            }
          }
          table {
            width: 100%;
            // margin-top: 40px;

            &.spec {
              td:nth-child(odd) {
                font-weight: bold;
                color: #145db4;
              }
              td:nth-child(even) {
                font-weight: 600;
                padding-left: 20px;
              }
            }
            &.index {
              white-space: pre-wrap;
              text-align: center;

              th {
                font-weight: 700;
                color: #145db4;
              }
              th:nth-child(1) {
                text-align: left;
              }
              td:nth-child(1) {
                text-align: left;
              }
              tbody {
                tr {
                  height: 80px;
                  border-bottom: 1px solid;
                  td {
                    padding-top: 20px;
                  }
                }
              }
            }
            &.spread {
              width: 100%;
              border-spacing: 0;
              // thead, tbody, tr, th, td { display: inline-block; }
              // thead tr {
              //     /* fallback */
              //     width: 97%;
              //     /* minus scroll bar width */
              //     width: -webkit-calc(100% - 16px);
              //     width:    -moz-calc(100% - 16px);
              //     width:         calc(100% - 16px);
              // }

              // tr:after {  /* clearing float */
              //     content: ' ';
              //     display: block;
              //     visibility: hidden;
              //     clear: both;
              // }

              tbody {
                height: 530px;
                overflow-y: auto;
                overflow-x: hidden;
              }

              tbody td,
              thead th {
                // padding: 0 10px;
                // min-width: 24%;  /* 19% is less than (100% / 5 cols) = 20% */
                // float: left;
              }
              th {
                background-color: #f8f9fa;
                color: #145db4;
                font-weight: 700;
                line-height: 19px;
                padding: 10px 0;
              }
              tbody {
                tr {
                  line-height: 50px;
                  border-bottom: 1px solid;
                }
                margin-top: 20px;
              }
            }
          }
        }
        .disclaimer {
          margin-top: 50px;
        }
      }

      &.account-open {
        .nav.nav-tabs {
          display: none;
        }
        h2 {
          margin-top: 20px;
        }
        ul {
          margin-top: 80px;
          font-weight: 600;
          li {
            margin-top: 10px;
          }
        }
        .nz-block {
          margin-top: 50px;
        }
        .block {
          margin-top: 20px;
          h5.title {
            font-style: italic;
            font-weight: 600;
            color: #145db4;
          }
          .brief {
            margin-top: 20px;
          }
          .demo {
            margin-top: 20px;
            color: #145db4;
          }
          .row {
            margin-top: 30px;
            .btn {
              width: 100%;
              color: white;
              font-weight: bold;
              padding: 15px 10px;
              background-color: #002653;
              border-radius: 30px;
              &.company {
                @include breakpoint(smartphone) {
                  margin-top: 20px;
                }
              }
            }
          }
          .wholesale {
            margin-top: 30px;
          }

          .para {
            margin-top: 10px;
          }

          .btn {
            color: white;
            font-weight: bold;
            padding: 15px 40px;
            background-color: #002653;
            border-radius: 30px;
          }
        }
      }

      &.platform {
        .intro {
          text-align: center;
          margin: auto;
          @include breakpoint(desktop) {
            width: 80%;
          }

          .para {
            margin-top: 40px;
          }
          img {
            width: 50%;
          }
          .btn {
            width: 220px;
            color: white;
            font-weight: bold;
            padding: 15px 40px;
            background-color: #002653;
            border-radius: 30px;
          }
          .row .download {
            @include breakpoint(smartphone) {
              margin-top: 10px;
            }
          }
        }

        .pc-version {
          ul {
            margin-top: 10px;
            list-style: none;
            padding-left: 0;
          }
        }
      }

      &.financial-report {
        .containerSlider {
          .bx-wrapper {
          }

          .bx-viewport {
            width: 100%;
            overflow: hidden;
            position: relative;

            // @include breakpoint(desktop){
            //   height: 345px;
            // }
          }
        }

        ul.photoBox2 {
          position: relative;
          transition-duration: 0s;
          transform: translate3d(0px, 0px, 0px);

          li {
            @include breakpoint(desktop) {
              float: left;
              width: 25%;
            }
            @include breakpoint(tablet) {
              float: left;
              width: 50%;
            }
            list-style: none;
            position: relative;
            width: 25%;
            article {
              text-align: center;
              position: relative;
              width: 213px;
              height: 325px;
              margin: 0 auto;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              background-image: url("banner/bg_white2.png");
              box-shadow:
                0 10px 16px 0 rgba(0, 0, 0, 0.1),
                0 6px 20px 0 rgba(0, 0, 0, 0.1);
              padding: 20px;
              margin-bottom: 20px;
              box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.2);
              padding: 20px 14px;
              p {
                font-weight: 400;
                line-height: 130%;
                text-align: center;
                color: #333333;
                font-family: "Roboto Condensed", "Noto Sans SC";

                font-size: 16px;

                vertical-align: middle;
                text-align: center;
                display: table-cell;
                height: 60px;
                width: 173px;
                overflow: hidden;
                &.dt {
                  font-weight: 600;
                  font-size: 17px;
                  height: auto;
                }
              }
            }
            hr {
              background-color: #ed830e;
              height: 1px;
              width: 100%;
              margin: 2px 0px 15px;
            }

            img {
              width: 130px;
              height: 175px;
              margin: 0 auto;
            }
          }
        }
      }

      .accreditation {
        background-color: #ffffff;
        padding: 40px 30px;
        ul {
          list-style: none;
        }
        .w3-col {
          float: left;
          @include breakpoint(desktop) {
            width: 50%;
          }
        }
        .photoBox article {
          box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.3);
          width: 230px;
          height: 325px;
          margin: 0 auto;
          background-image: url("./banner/bg_white2.png");
          padding: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 120px;
          }
          hr {
            background-color: #ed830e;
            height: 1px;
            width: 100%;
            border: 0;
            border-top: 1px solid #eee;
            margin: 20px 0;
          }
          h1 {
            color: #333333;
            font-size: 18px;
            font-weight: 500;
            line-height: 100%;
          }
          p {
            color: #333333;
            font-size: 15px;
            line-height: 150%;
            font-weight: 300;
          }
        }
      }
      &.announcement {
        ul {
          list-style: none;
          @include breakpoint(smartphone) {
            padding-left: 0;
          }
          .loading {
            text-align: center;
          }
        }
        .relationList > li {
          font-size: 16px;
          font-weight: 500;
          padding: 5px 10px;
          overflow: hidden;

          a {
            color: #1f1f1f;
          }
          .w3-col {
            float: left;
            @include breakpoint(smartphone) {
              float: unset;
            }
            &.m2 {
              width: 16.66666%;
            }
            &.m10 {
              width: 83.33333%;
            }
          }
          &.title {
            color: #ed830e;
            font-size: 15px;
            font-weight: 500;
            ul {
              overflow: hidden;
              border-width: 0 0 1px;
              border-color: #dddddd;
              border-style: solid;
            }
          }
        }
      }
      &.prospectus {
        .containerRight {
          background-color: #ffffff;
          padding: 70px 30px 40px;
          @include breakpoint(smartphone) {
            padding: 40px 0;
          }
          > ul {
            list-style: none;
          }
          .relationList2 > li {
            margin-bottom: 20px;
            a {
              display: table;
              width: 100%;
              ul {
                background-image: url("./banner/bg_white2.png");
                @include breakpoint(smartphone) {
                  padding-left: 0;
                }
                overflow: hidden;
                width: 100%;
                height: 110px;
                display: table-row;
                .w3-col {
                  float: left;
                  @include breakpoint(smartphone) {
                    float: unset;
                  }
                  &.l2 {
                    width: 16.6666%;
                  }
                  &.l8 {
                    width: 66.6666%;
                    @include breakpoint(smartphone) {
                      width: 36.6666%;
                    }
                  }
                  &.l1 {
                    width: 8.3333%;
                  }
                }
                li {
                  color: #333333;
                  font-family: "Roboto Condensed", "Noto Sans SC";

                  font-weight: 400;
                  font-size: 15px;
                  height: 110px;
                  vertical-align: middle;
                  display: table-cell;
                  text-align: center;
                  position: relative;

                  &:nth-child(2) {
                    position: relative;
                    font-size: 17px;
                    text-align: left;
                    padding: 0px 10px;
                    margin-left: 20px;
                    margin-right: 20px;
                    &::after {
                      content: "";
                      background-color: #cccccc;
                    }
                  }
                  &:last-child {
                    &::after {
                      width: 0;
                    }
                  }
                  &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 0;
                    height: 100%;
                  }
                  &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    height: 70px;
                    width: 1px;
                    background-color: #f39838;
                    right: -0px;
                    top: 20px;
                  }
                }
              }
            }
          }
        }
      }
      &.notifications {
        .containerRight {
          padding: 70px 30px 40px;
          .title {
            font-weight: bold;
          }
          .brief {
            margin-top: 20px;
          }
          .cert {
            margin-top: 40px;
            ul {
              display: inline-block;
              list-style: none;
              li {
                font-weight: bold;
              }
            }
          }
        }
      }
      &.governance {
        .containerRight {
          background-color: #ffffff;
          padding: 70px 30px 40px;
          @include breakpoint(smartphone) {
            padding: 40px 0;
          }
          ul {
            list-style: none;
          }
          .w3-col {
            float: left;
            &.l3 {
              width: 24.999%;
              @include breakpoint(smartphone) {
                width: 49.999%;
              }
            }
          }
          .relationList3 > li {
            margin-bottom: 20px;
            a {
              width: 100%;
              ul {
                box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.2);
                padding-left: 9px;
                padding-right: 9px;
                background-image: url(./banner/bg_white2.png);
                overflow: hidden;
                width: 90%;
                height: 149px;
                display: block;
                padding: 15px 0px 25px;
                li {
                  font-size: 16px;
                  line-height: 110%;
                  color: #333333;
                  font-family: "Roboto Condensed", "Noto Sans SC";
                  height: auto;
                  display: block;
                  text-align: center;
                  position: relative;
                  &:first-child {
                    padding: 5px 0px;
                    font-weight: 600;
                    &::after {
                      content: "";
                      display: block;
                      height: 1px;
                      width: 90% !important;
                      margin: 0 auto;
                      background-color: #f39838;
                      position: relative;
                      top: 5px;
                    }
                  }
                  &:nth-child(2) {
                    font-size: 15px;
                    text-align: center;
                    padding: 10px 0px;
                  }
                  div.cloud {
                    width: 41px;
                    height: 34px;
                    margin: 0 auto;
                    background-image: url(./banner/downloadcloud.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                  }
                }
              }
            }
          }
        }
      }
      &.contact {
        .block {
          margin-top: 30px;
          .svg-inline--fa {
            margin-right: 10px;
            margin-top: 10px;
            color: #000066;
          }
        }
      }
      &.deposit-withdrawal {
        //font-family: Roboto,"Noto Sans SC","Noto Sans TC",Arial,Helvetica,sans-serif,"Segoe UI","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
        .subsidiary.nav.nav-tabs {
          display: none;
        }
        .deposit,
        .withdrawal {
          background-color: #f8f9fa;
          margin-top: 10px;
          padding: 10px;
          @include breakpoint(desktop) {
            margin-top: 40px;
            padding: 40px 80px;
          }
          @include breakpoint(tablet) {
            margin-top: 20px;
            padding: 20px 40px;
          }

          h3 {
            font-weight: 600;
            font-style: italic;
            color: #145db4;
            margin-top: 40px;
          }
          h5 {
            margin-top: 20px;
            font-weight: 600;
            color: #145db4;
          }
          p {
            margin-top: 20px;
            font-weight: 600;
          }
          ul {
            list-style-type: decimal;
            padding-left: 18px;
            &.note {
              font-style: italic;
            }
            li {
              line-height: 40px;
            }
          }
        }
        .customContainer {
          padding-right: 0;
          width: 100%;
          margin: 0 auto;
          padding-left: 24px;
          padding-right: 24px;
          max-width: 1360px;
          .depositBox {
            background-color: #fff;
            @include breakpoint(desktop) {
              width: 660px;
              padding: 50px 50px 65px 68px;
            }
            width: 100%;
            padding: 15px;
            margin-left: auto;

            table {
              font-size: 16px;
              color: #241e82;
              span.red {
                color: #ff0050;
                font-weight: 500;
              }
              tr,
              td {
                vertical-align: top;
                line-height: 24px;
              }
              th {
                font-weight: 700;
                padding: 18px 10px;
                border-bottom: 2px solid #dadaec;
                &:nth-child(1),
                &:nth-child(1) {
                  width: 30%;
                }
              }
              td {
                padding: 14px 10px;
                font-weight: 400;
                border-bottom: 1px solid #dadaec;
              }
            }
            a {
              transition: 0.3s all;
            }
            ul {
              display: list-item;
              list-style-type: none;
            }
            li {
              line-height: 1.6;
              margin-bottom: 1.2em;
              color: #241e82;
              list-style-type: decimal;
            }
            h3 {
              margin: 0;
              font-size: 1em;
              line-height: 1.178em;
              color: #3b26d1;
              font-weight: 700;
              margin-bottom: 1em;
            }
            h4 {
              color: #3b26d1;
              line-height: 1.5;
              margin-bottom: 13px;
              margin-top: 26px;

              font-weight: 700;
              font-size: 16px;
            }
            h5 {
              font-size: 16px;
              line-height: 1.2;
              font-weight: 700;
              margin-bottom: 16px;
              color: #241e82;
              &.floatText {
                margin-top: 10px;
                margin-bottom: 10px;
                span {
                  font-weight: 700;
                  float: left;
                  width: 156px;
                  line-height: 1.6;
                }
                .content {
                  padding-left: 170px;
                  font-weight: 400;
                  line-height: 1.6;
                }
                &:after {
                  content: ".";
                  display: block;
                  height: 0;
                  clear: both;
                  visibility: hidden;
                }
              }
            }

            ul.list {
              font-size: 14px;
              padding: 0;
              margin: 0;
              margin-left: 1em;
              margin-bottom: 15px;
            }
            .noteBox {
              font-size: 12px;
              line-height: 20px;
              color: #7d87cc;
              text-align: left;
              text-align: justify;
              font-weight: 400;
              .alertNote {
                line-height: 1.6;
                margin-bottom: 16px;
                & > span {
                  font-weight: 700;
                }
              }
              .noteList {
                padding: 0;
                margin: 0;
                margin-left: 1.5em;
                margin-bottom: 16px;
                &.num {
                  margin-left: 1em;
                  li {
                    margin-bottom: 0.8em;
                  }
                }
              }
            }
          }
        }

        .mainSection {
          min-height: 15.86em;
          padding-top: 5.642em;
          padding-bottom: 2.5em;
          font-size: 28px;
          background-color: #241e82;
          margin-top: 2em;
          &.inPage {
            margin-top: 3.85em;
            height: auto;
            .bodyBox {
              @include breakpoint(desktop) {
                float: left;
              }
            }
            .depositBox {
              margin-bottom: 16px;
              @include breakpoint(desktop) {
                margin-top: unset;
              }
              margin-top: 20px;
            }
          }
          .customContainer {
            .bodyBox {
              padding-left: 1.8em;
              .insideBox {
                max-width: 100%;
                padding-right: 20px;
                .kvbTitle {
                  color: #fff;
                  font-size: 0.571em;
                  margin-bottom: 17px;
                  h3 {
                    font-size: 1.875em;
                    line-height: 1.16;
                  }
                }
                p {
                  font-weight: 300;
                  font-size: 17px;
                  line-height: 30px;
                  margin-top: 0;
                  margin-bottom: 0;
                  line-height: 1.6;
                  color: #fff;
                  text-align: left;
                }
              }
            }
          }
        }
        .depositSec {
          font-size: 28px;
          margin-top: 16px;
          &.first {
            margin-top: -6.375em;
            @include breakpoint(desktop) {
              margin-top: -10.375em;
            }
          }
        }
      }
      &.location {
        .block {
          margin-top: 40px;
          .intro {
            text-align: center;
            h2 {
              font-weight: bold;
            }
            .summary {
              font-weight: 600;
              font-style: italic;
              color: #145db4;
              margin: 30px 0;
              font-size: 20px;
            }
            .brief {
              width: 80%;
              margin: auto;
            }
          }

          ul {
            @include breakpoint(desktop) {
              display: inline-flex;
            }
            list-style: none;
            li {
              padding: 0 20px;
              width: 100%;
              @include breakpoint(desktop) {
                width: 20%;
                border-right: 1px solid;
                &:nth-child(1) {
                  border-left: 1px solid;
                }
              }

              .title {
                font-size: 24px;
                color: #145db4;
                font-weight: bold;
              }
              .text {
                margin-top: 10px;
              }
            }
          }
          // .intro{
          //   padding-top: 15px;
          //   @include breakpoint(smartphone){
          //     padding-left: 15px;
          //   }
          //   .asic{
          //     margin-top: 30px;
          //   }
          // }
          article {
            box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.3);
            width: 230px;
            height: 325px;
            margin-top: 20px;
            background-image: url("./banner/bg_white2.png");
            padding: 10px;
            margin-bottom: 20px;
            img {
              width: 100%;
              height: 120px;
            }
            hr {
              background-color: #ed830e;
              height: 1px;
              width: 100%;
              border: 0;
              border-top: 1px solid #eee;
              margin: 20px 0;
            }
            h1 {
              color: #333333;
              font-size: 18px;
              font-weight: 500;
              line-height: 100%;
            }
            p {
              color: #333333;
              font-size: 15px;
              line-height: 150%;
              font-weight: 300;
            }
          }
        }
      }
    }

    .section1 {
      background-image: url("./banner/banner_home.webp"), url("./banner/banner_home.jpg");
      width: 100%;
      height: 600px;
      .text {
        text-align: center;
        color: white;
        padding-top: 150px;
        h1 {
          font-size: 70px;
          font-weight: bold;
        }
        h3.line1 {
          margin-top: 20px;
        }
      }
    }
    .section2 {
      img {
        display: block;
        width: 80%;
        margin: auto;
        height: 500px;
        margin-top: -80px;
      }
    }

    .warning-popup {
      p {
        color: #002653;
      }
    }
  }
}
